import { Brands } from "../../components/brands";
import { Services } from "../../components/services";

export function About() {
  return <>
      <section className="bg-success py-5">
        <div className="container">
            <div className="row align-items-center py-5">
                <div className="col-md-8 text-white">
                    <h1>About Us</h1>
                    <p>
                    
                    Ever since our inception in the year 2007, we, “Paper N Films International”, are reckoned as the most venerated Exporter and Manufacturer, Wholesaler, Trader, Supplier, Service Provider, Importer of a quality assured array of Packing Papers & Films, Waterproof Papers, Water Proofing Chemicals etc. These products are procured from the vendors of high repute after doing extensive market research. Owing to their remarkable features such as longer functional life, excellent tear resistance, eco- friendliness and cost effectiveness, these are highly acclaimed by our esteemed clients.

                    </p>
                    <p>

                    In order to deliver a defect-free gamut of products to the customers, our auditors test these on various parameters to gauge their quality. Moreover, these goods are packed using tamper proof material to ensure their safe transit. Timely delivery of the consignments is taken care of by a team of highly experienced logistics personnel via various modes of shipment. Keeping convenience of the esteemed clients in mind, we accept payment through various easy modes. Furthermore, customization can also be done by us with respect to these products as per the clients' specifications.
                    </p>
 
                    <p>
                    Our mentor “Mr. Pareshkumar Bachubhai Shah[Owner & CEO]”, has always fostered us in all our endeavors. His profound business acumen and remarkable managerial skills have always guided us to leave our contemporary competitors far behind. Without his support, it would have been a far fetched dream for us to muster a vast clientele across the nation.
                    </p>
                </div>
                <div className="col-md-4">
                    <a target="_blank" href="https://in.linkedin.com/in/paresh-shah-9a0618155">
                    <img src="https://media.licdn.com/dms/image/C5603AQHlk-5awqjk-g/profile-displayphoto-shrink_200_200/0/1517072262114?e=2147483647&v=beta&t=RidbjTATU-VRzHaZT4zaWkvK3VQ4nZfPDo9eQhDQPrc" alt="Paresh Shah" />
                    </a>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Close Banner --> */}

    {/* <!-- Start Section --> */}
    <Services />
    {/* <!-- End Section --> */}

    {/* <!-- Start Brands --> */}
    <Brands />
    {/* <!--End Brands--> */}
  </>
}