import React from 'react';
import './App.css';
import { NavBar } from './components/navigation';
import { Header } from './components/header';
import { Footer } from './components/footer';
import { Outlet } from 'react-router-dom';


function App() {
  return (
    <div className="App">
      {/* <!-- Start Top Nav --> */}
    <NavBar />
    {/* <!-- Close Top Nav --> */}


    {/* <!-- Header --> */}
    <Header />
    {/* <!-- Close Header --> */}

  
    <Outlet />

    {/* <!-- Start Footer --> */}
    <Footer />
    {/* <!-- End Footer --> */}

    {/* <!-- Start Script --> */}
    <script src="assets/js/jquery-1.11.0.min.js"></script>
    <script src="assets/js/jquery-migrate-1.2.1.min.js"></script>
    <script src="assets/js/bootstrap.bundle.min.js"></script>
    <script src="assets/js/templatemo.js"></script>
    <script src="assets/js/custom.js"></script>
    {/* <!-- End Script --> */}
    </div>
  );
}

export default App;
