import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Home } from './pages/home';
import { About } from './pages/about';
import { Shop } from './pages/shop';
import { ShopSingle } from './pages/shop-single';
import { Contact } from './pages/contact';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    // loader: rootLoader,
    children: [
      {
        path: '',
        element: <Home />
      },
      {
        path: 'home',
        element: <Home />
      },
      {
        path: "about",
        element: <About />,
        // loader: teamLoader,
      },
      {
        path: "shop",
        element: <Shop />
        // loader: teamLoader,
      },
      {
        path: "shop/:id",
        element: <ShopSingle />
        // loader: teamLoader,
      },
      {
        path: "contact",
        element: <Contact />,
        // loader: teamLoader,
      },
    ],
  },
]);


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);


// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
