import { useEffect, useState } from "react";


export const useData = () => {
  const [data, setData] = useState<any>({});

  useEffect(() => {
    fetch('/assets/data/data.json')
      .then((res) => res.json())
      .then((data) => { 
        setData(data)
      });
  }, []);

  return data;
};