import { Banner } from "../../components/banner";
import { Brands } from "../../components/brands";
import { Services } from "../../components/services";
import { useData } from "../../hooks/use-data";

export function Home() {
  const data = useData();
  return <>

    {/* <!-- Modal --> */}
    <div className="modal fade bg-white" id="templatemo_search" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="w-100 pt-1 mb-5 text-right">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form action="" method="get" className="modal-content modal-body border-0 p-0">
          <div className="input-group mb-2">
            <input type="text" className="form-control" id="inputModalSearch" name="q" placeholder="Search ..." />
            <button type="submit" className="input-group-text bg-success text-light">
              <i className="fa fa-fw fa-search text-white"></i>
            </button>
          </div>
        </form>
      </div>
    </div>



    {/* <!-- Start Banner Hero --> */}
    <Banner />
    {/* <!-- End Banner Hero --> */}


    {/* <!-- Start Categories of The Month --> */}
    <section className="container py-5">
      <div className="row text-center pt-3">
        <div className="col-lg-6 m-auto">
          <h1 className="h1">Featured Products</h1>
          <p>
            Get ideal products for your requirements
          </p>
        </div>
      </div>
      <div className="row">
        {
          data.featured?.map((x:any, idx: number) => <div className="col-12 col-md-4 p-5 mt-3">
            <a href="#"><img src={x.image} className="rounded-circle img-fluid border" style={{ height: '300px', width: '300px' }} /></a>
            <h5 className="text-center mt-3 mb-3">{x.name}</h5>
            <p className="text-center"><a className="btn btn-success">View</a></p>
          </div>)
        }
        {/* <div className="col-12 col-md-4 p-5 mt-3">
          <a href="#"><img src="./assets/img/category_img_01.jpg" className="rounded-circle img-fluid border" /></a>
          <h5 className="text-center mt-3 mb-3">Watches</h5>
          <p className="text-center"><a className="btn btn-success">Go Shop</a></p>
        </div> */}
        {/* <div className="col-12 col-md-4 p-5 mt-3">
          <a href="#"><img src="./assets/img/category_img_02.jpg" className="rounded-circle img-fluid border" /></a>
          <h2 className="h5 text-center mt-3 mb-3">Shoes</h2>
          <p className="text-center"><a className="btn btn-success">Go Shop</a></p>
        </div>
        <div className="col-12 col-md-4 p-5 mt-3">
          <a href="#"><img src="./assets/img/category_img_03.jpg" className="rounded-circle img-fluid border" /></a>
          <h2 className="h5 text-center mt-3 mb-3">Accessories</h2>
          <p className="text-center"><a className="btn btn-success">Go Shop</a></p>
        </div> */}
      </div>
    </section>
    {/* <!-- End Categories of The Month --> */}

    <Services />


    {/* <!-- Start Featured Product --> */}
    <section className="">
      <div className="container py-5">
        <div className="row text-center py-3">
          <div className="col-lg-6 m-auto">
            <h1 className="h1">Videos</h1>
            <p>
            Helpful Videos on How to use various papers
            </p>
          </div>
        </div>
        <div className="row">
          {
            data.videos?.map((x: any, idx: number) =>  <div key={idx} className="col-12 col-md-4 mb-4">
            <div className="card h-100">
              <iframe src={x.link} style={{ height: '300px' }} />
              {/* <a href="shop-single.html"> */}
                {/* <img src="./assets/img/feature_prod_01.jpg" className="card-img-top" alt="..." /> */}
              {/* </a> */}
              <div className="card-body">
                {/* <ul className="list-unstyled d-flex justify-content-between">
                  <li>
                    <i className="text-warning fa fa-star"></i>
                    <i className="text-warning fa fa-star"></i>
                    <i className="text-warning fa fa-star"></i>
                    <i className="text-muted fa fa-star"></i>
                    <i className="text-muted fa fa-star"></i>
                  </li>
                  <li className="text-muted text-right">$240.00</li>
                </ul> */}
                <h4 className="text-decoration-none text-dark">{x.name}</h4>
                <p className="card-text">
                  {x.description}
                </p>
                {/* <p className="text-muted">Reviews (24)</p> */}
              </div>
            </div>
          </div>)
          }
        </div>
      </div>
    </section>
    {/* <!-- End Featured Product --> */}

    <Brands />

  </>
}