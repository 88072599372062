import { useParams } from "react-router-dom";
import { useData } from "../../hooks/use-data";
import { useEffect, useState } from "react";

export function ShopSingle() {
  const data = useData();
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [category, setCategory] = useState<string>('');

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      console.log(id);
      let sel: any | null = null;
      let selectedCategory: string = '';
      console.log(data);

      data?.products?.forEach((p: any) => {
        if (p.items) {
          p.items.forEach((item: any) => {
            if (item.sku === `${id}`) {
              console.log('set hua', item)
              sel = item;
              selectedCategory = p.category;
            }
          })
        }
      });

      if (sel) {
        setSelectedProduct(sel);
        setCategory(selectedCategory);
      }
    }
  }, [id, data]);

  if (!selectedProduct) {
    return <div>Loading...</div>
  }

  return <>
      {/* <!-- Open Content --> */}
    <section className="bg-light">
        <div className="container pb-5">
            <div className="row">
                <div className="col-lg-5 mt-5">
                    <div className="card mb-3">
                        <img className="card-img img-fluid" src={`/${selectedProduct.image}`} alt={selectedProduct.name} id="product-detail" />
                    </div>
                    <div className="row">
                        {/* <!--Start Controls--> */}
                        <div className="col-1 align-self-center">
                            <a href="#multi-item-example" role="button" data-bs-slide="prev">
                                <i className="text-dark fas fa-chevron-left"></i>
                                <span className="sr-only">Previous</span>
                            </a>
                        </div>
                        {/* <!--End Controls--> */}
                        {/* <!--Start Carousel Wrapper--> */}
                        <div id="multi-item-example" className="col-10 carousel slide carousel-multi-item" data-bs-ride="carousel">
                            {/* <!--Start Slides--> */}
                            <div className="carousel-inner product-links-wap" role="listbox">

                                {/* <!--First slide--> */}
                                <div className="carousel-item active">
                                    <div className="row">
                                      {
                                        selectedProduct.extra?.map((x: any, idx: number) => <div key={idx} className="col-4">
                                          <a href="#">
                                              <img className="card-img img-fluid" src={`/${x}`} alt={selectedProduct.name} />
                                          </a>
                                      </div>)
                                      }
                                        {/* <div className="col-4">
                                            <a href="#">
                                                <img className="card-img img-fluid" src="assets/img/product_single_01.jpg" alt="Product Image 1" />
                                            </a>
                                        </div>
                                        <div className="col-4">
                                            <a href="#">
                                                <img className="card-img img-fluid" src="assets/img/product_single_02.jpg" alt="Product Image 2" />
                                            </a>
                                        </div>
                                        <div className="col-4">
                                            <a href="#">
                                                <img className="card-img img-fluid" src="assets/img/product_single_03.jpg" alt="Product Image 3" />
                                            </a>
                                        </div> */}
                                    </div>
                                </div>
                                {/* <!--/.First slide--> */}

                                {/* <!--Second slide--> */}
                                {/* <div className="carousel-item">
                                    <div className="row">
                                        <div className="col-4">
                                            <a href="#">
                                                <img className="card-img img-fluid" src="assets/img/product_single_04.jpg" alt="Product Image 4" />
                                            </a>
                                        </div>
                                        <div className="col-4">
                                            <a href="#">
                                                <img className="card-img img-fluid" src="assets/img/product_single_05.jpg" alt="Product Image 5" />
                                            </a>
                                        </div>
                                        <div className="col-4">
                                            <a href="#">
                                                <img className="card-img img-fluid" src="assets/img/product_single_06.jpg" alt="Product Image 6" />
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <!--/.Second slide--> */}

                                {/* <!--Third slide--> */}
                                {/* <div className="carousel-item">
                                    <div className="row">
                                        <div className="col-4">
                                            <a href="#">
                                                <img className="card-img img-fluid" src="assets/img/product_single_07.jpg" alt="Product Image 7" />
                                            </a>
                                        </div>
                                        <div className="col-4">
                                            <a href="#">
                                                <img className="card-img img-fluid" src="assets/img/product_single_08.jpg" alt="Product Image 8" />
                                            </a>
                                        </div>
                                        <div className="col-4">
                                            <a href="#">
                                                <img className="card-img img-fluid" src="assets/img/product_single_09.jpg" alt="Product Image 9" />
                                            </a>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <!--/.Third slide--> */}
                            </div>
                            {/* <!--End Slides--> */}
                        </div>
                        {/* <!--End Carousel Wrapper--> */}
                        {/* <!--Start Controls--> */}
                        <div className="col-1 align-self-center">
                            <a href="#multi-item-example" role="button" data-bs-slide="next">
                                <i className="text-dark fas fa-chevron-right"></i>
                                <span className="sr-only">Next</span>
                            </a>
                        </div>
                        {/* <!--End Controls--> */}
                    </div>
                </div>
                {/* <!-- col end --> */}
                <div className="col-lg-7 mt-5">
                    <div className="card h-100">
                        <div className="card-body">
                            <h1 className="h2">{selectedProduct.name}</h1>
                            <p className="h3 py-2">₹ {selectedProduct.rate} / {selectedProduct.unit}</p>
                            {/* <p className="py-2">
                                <i className="fa fa-star text-warning"></i>
                                <i className="fa fa-star text-warning"></i>
                                <i className="fa fa-star text-warning"></i>
                                <i className="fa fa-star text-warning"></i>
                                <i className="fa fa-star text-secondary"></i>
                                <span className="list-inline-item text-dark">Rating 4.8 | 36 Comments</span>
                            </p> */}
                            <ul className="list-inline">
                                <li className="list-inline-item">
                                    <h6>Category:</h6>
                                </li>
                                <li className="list-inline-item">
                                    <p className="text-muted"><strong>{category}</strong></p>
                                </li>
                            </ul>

                            <h6>Description:</h6>
                            <p>{selectedProduct.description}</p>
                            {/* <ul className="list-inline">
                                <li className="list-inline-item">
                                    <h6>Avaliable Color :</h6>
                                </li>
                                <li className="list-inline-item">
                                    <p className="text-muted"><strong>White / Black</strong></p>
                                </li>
                            </ul> */}

                            <h6>Specification:</h6>
                            <ul className="list-unstyled pb-3">
                              {
                                selectedProduct.specification?.map((x: any, idx: number) => <li key={idx}>
                                  {x}
                                </li>)
                              }
                            </ul>

                            {/* <form action="" method="GET">
                                <input type="hidden" name="product-title" value="Activewear" />
                                <div className="row">
                                    <div className="col-auto">
                                        <ul className="list-inline pb-3">
                                            <li className="list-inline-item">Size :
                                                <input type="hidden" name="product-size" id="product-size" value="S" />
                                            </li>
                                            <li className="list-inline-item"><span className="btn btn-success btn-size">S</span></li>
                                            <li className="list-inline-item"><span className="btn btn-success btn-size">M</span></li>
                                            <li className="list-inline-item"><span className="btn btn-success btn-size">L</span></li>
                                            <li className="list-inline-item"><span className="btn btn-success btn-size">XL</span></li>
                                        </ul>
                                    </div>
                                    <div className="col-auto">
                                        <ul className="list-inline pb-3">
                                            <li className="list-inline-item text-right">
                                                Quantity
                                                <input type="hidden" name="product-quanity" id="product-quanity" value="1" />
                                            </li>
                                            <li className="list-inline-item"><span className="btn btn-success" id="btn-minus">-</span></li>
                                            <li className="list-inline-item"><span className="badge bg-secondary" id="var-value">1</span></li>
                                            <li className="list-inline-item"><span className="btn btn-success" id="btn-plus">+</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="row pb-3">
                                    <div className="col d-grid">
                                        <button type="submit" className="btn btn-success btn-lg" name="submit" value="buy">Buy</button>
                                    </div>
                                    <div className="col d-grid">
                                        <button type="submit" className="btn btn-success btn-lg" name="submit" value="addtocard">Add To Cart</button>
                                    </div>
                                </div>
                            </form> */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- Close Content --> */}

  </>
}