import { useData } from "../hooks/use-data"

export function Brands() {
  const data = useData();
  return <section className="bg-light py-5">
    <div className="container my-4">
      <div className="row text-center py-3">
        <div className="col-lg-6 m-auto">
          <h1 className="h1">Our Associations and Memberships</h1>
          <p>Some of the brands we associated with or are members of...</p>
        </div>
        <div className="col-lg-9 m-auto tempaltemo-carousel">
          <div className="row d-flex flex-row">
            {/* <!--Controls--> */}
            <div className="col-1 align-self-center">
              <a className="h1" href="#templatemo-slide-brand" role="button" data-bs-slide="prev">
                <i className="text-light fas fa-chevron-left"></i>
              </a>
            </div>
            {/* <!--End Controls--> */}

            {/* <!--Carousel Wrapper--> */}
            <div className="col">
              <div className="carousel slide carousel-multi-item pt-2 pt-md-0" id="templatemo-slide-brand" data-bs-ride="carousel">
                {/* <!--Slides--> */}
                <div className="carousel-inner product-links-wap" role="listbox">

                <div className="carousel-item active">
                    <div className="row">
                      {
                        data?.brands?.map((x: any, idx: number) =>  <div key={idx} className="col-3 p-md-3">
                        <a href={x.link} target="_blank" ><img className="img-fluid brand-img" src={x.image} alt={x.name} /></a>
                      </div>)
                      }
                    </div>
                  </div>

                  
                  {/* <!--First slide--> */}
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-3 p-md-5">
                        <a href="#"><img className="img-fluid brand-img" src="assets/img/brand_01.png" alt="Brand Logo" /></a>
                      </div>
                      <div className="col-3 p-md-5">
                        <a href="#"><img className="img-fluid brand-img" src="assets/img/brand_02.png" alt="Brand Logo" /></a>
                      </div>
                      <div className="col-3 p-md-5">
                        <a href="#"><img className="img-fluid brand-img" src="assets/img/brand_03.png" alt="Brand Logo" /></a>
                      </div>
                      <div className="col-3 p-md-5">
                        <a href="#"><img className="img-fluid brand-img" src="assets/img/brand_04.png" alt="Brand Logo" /></a>
                      </div>
                    </div>
                  </div>
                  {/* <!--End First slide--> */}

                  {/* <!--Second slide--> */}
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-3 p-md-5">
                        <a href="#"><img className="img-fluid brand-img" src="assets/img/brand_01.png" alt="Brand Logo" /></a>
                      </div>
                      <div className="col-3 p-md-5">
                        <a href="#"><img className="img-fluid brand-img" src="assets/img/brand_02.png" alt="Brand Logo" /></a>
                      </div>
                      <div className="col-3 p-md-5">
                        <a href="#"><img className="img-fluid brand-img" src="assets/img/brand_03.png" alt="Brand Logo" /></a>
                      </div>
                      <div className="col-3 p-md-5">
                        <a href="#"><img className="img-fluid brand-img" src="assets/img/brand_04.png" alt="Brand Logo" /></a>
                      </div>
                    </div>
                  </div>
                  {/* <!--End Second slide--> */}

                  {/* <!--Third slide--> */}
                  <div className="carousel-item">
                    <div className="row">
                      <div className="col-3 p-md-5">
                        <a href="#"><img className="img-fluid brand-img" src="assets/img/brand_01.png" alt="Brand Logo" /></a>
                      </div>
                      <div className="col-3 p-md-5">
                        <a href="#"><img className="img-fluid brand-img" src="assets/img/brand_02.png" alt="Brand Logo" /></a>
                      </div>
                      <div className="col-3 p-md-5">
                        <a href="#"><img className="img-fluid brand-img" src="assets/img/brand_03.png" alt="Brand Logo" /></a>
                      </div>
                      <div className="col-3 p-md-5">
                        <a href="#"><img className="img-fluid brand-img" src="assets/img/brand_04.png" alt="Brand Logo" /></a>
                      </div>
                    </div>
                  </div>
                  {/* <!--End Third slide--> */}

                </div>
                {/* <!--End Slides--> */}
              </div>
            </div>
            {/* <!--End Carousel Wrapper--> */}

            {/* <!--Controls--> */}
            <div className="col-1 align-self-center">
              <a className="h1" href="#templatemo-slide-brand" role="button" data-bs-slide="next">
                <i className="text-light fas fa-chevron-right"></i>
              </a>
            </div>
            {/* <!--End Controls--> */}
          </div>
        </div>
      </div>
    </div>
  </section>
}