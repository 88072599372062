import { useData } from "../hooks/use-data";

export function NavBar() {
  const data = useData();

  return  <nav className="navbar navbar-expand-lg bg-dark navbar-light d-none d-lg-block" id="templatemo_nav_top">
    <div className="container text-light">
        <div className="w-100 d-flex justify-content-between">
            <div>
              {
                data.navs?.map((x: any, idx: number) => <span key={idx}>
                  <i className={`fa ${x.icon} mx-2`}></i>
                  {
                    x.action === 'map' ? 
                    <a className="navbar-sm-brand text-light text-decoration-none" href={x.link ? x.link: ''} target="_blank" >{x.value}</a> :
                    <a className="navbar-sm-brand text-light text-decoration-none" href={x.action ? `${x.action}${x.value}`: ''}>{x.value}</a>
                  }
                </span>)
              }
            </div>
            <div>
              {
                data.social?.map((x: any, idx: number) => 
                  <a className="text-light" href={x.value} target="_blank"><i className={`${x.icon} fa-sm fa-fw me-2`}></i></a>
                )
              }
            </div>
        </div>
    </div>
  </nav>
}