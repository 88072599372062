import { useData } from "../hooks/use-data"

export function Services() {
  const data = useData();
  return <section className="bg-light">
    <div className="container py-5">
      <div className="row text-center pt-5 pb-3">
        <div className="col-lg-6 m-auto">
          <h1 className="h1">Reasons To Use Our Services</h1>
          <p>
            We are true to our words.
          </p>
        </div>
      </div>
      <div className="row">

        {
          data.services?.map((x: any, idx: number) => <div key={idx} className="col-md-6 col-lg-3 pb-5">
          <div className="h-100 py-5 services-icon-wap shadow">
            <div className="h1 text-success text-center"><i className={`fa ${x.icon} fa-lg`}></i></div>
            <h2 className="h5 mt-4 text-center">{x.title}</h2>
            <p className="text-center">{x.message}</p>
          </div>
        </div>)
        }
      </div>

    </div>
  </section>
}