import { useData } from "../hooks/use-data";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
export function Footer() {
  const data = useData();
  const [count, setCount] = useState<{ local: number; global: number }>({ local: 0, global: 0 });

  // eslint-disable-next-line
  const baseUrl = location.origin;
  const path = useLocation();

  useEffect(() => {
    if (baseUrl !== 'http://localhost:3000') {
      fetch(`https://api.counterapi.dev/v1/justry.in/${path.pathname === '/' ? 'home' : path.pathname.replace(/\//g, '-')}/up`)
        .then(res => res.json())
        .then(async (localCounter) => {
          console.log(localCounter);
          const counter = await fetch(`https://api.counterapi.dev/v1/justry.in/global/up`)
            .then(res => res.json())

          console.log(counter)
          setCount({
            global: counter.count,
            local: localCounter.count
          })
        });
    }

  
  }, []);

  return <footer className="bg-dark" id="tempaltemo_footer">
    <div className="container">
      <div className="row">
        <div className="col-md-4 pt-5">
          <h2 className="h2 text-success border-bottom pb-3 border-light logo">
            {data.name}
          </h2>
          <ul className="list-unstyled text-light footer-link-list">
            {
              data.contact ? <>
              <li>
                <i className="fas fa-map-marker-alt fa-fw"></i>
                {data.contact['line-1']}<br />
                {data.contact['line-2']}<br />
                {data.contact['line-3']}<br />
                {data.contact.location}, {data.contact.city} {data.contact.pincode}<br />
              </li>
                
              {
                data.contact.phone.map((x: string, idx: number) => <li key={idx}>
                  <i className="fa fa-phone fa-fw"></i>
                  <a key={idx} className="text-decoration-none" href={`tel:${x}`}>
                    {x}
                  </a>
                </li>
                )
              }
              <li>
                <i className="fa fa-envelope fa-fw"></i>
                <a className="text-decoration-none" href={`mailto:${data.contact.email}`}>{data.contact.email}</a>
              </li>
              </> : null
            }
          </ul>
        </div>

        <div className="col-md-4 pt-5">
          <h2 className="h2 text-light border-bottom pb-3 border-light">Products</h2>
          <ul className="list-unstyled text-light footer-link-list">
            {
              data.products?.map((x: any, idx: number) => 
                <li className="text-capitalize" key={idx}>
                  <a className="text-decoration-none" href="/">{x.category}</a>
                  </li>
              )
            }
          </ul>
        </div>

        <div className="col-md-4 pt-5">
          <h2 className="h2 text-light border-bottom pb-3 border-light">Further Info</h2>
          <ul className="list-unstyled text-light footer-link-list">
            <li><a className="text-decoration-none" href="/">Home</a></li>
            <li><a className="text-decoration-none" href="/about">About Us</a></li>
            <li><a className="text-decoration-none" href="/shop">Products</a></li>
            <li><a className="text-decoration-none" href="#">FAQs</a></li>
            <li><a className="text-decoration-none" href="/contact">Contact</a></li>
          </ul>
        </div>
      </div>
      <div className="row text-light mb-4">
        <div className="col-12 mb-3">
          <div className="w-100 my-3 border-top border-light"></div>
        </div>
        <div className="col-lg-4 me-auto">
          <ul className="list-inline text-left footer-icons">
            {
              data.social?.map((x: any, idx: number) => <li key={idx} className="list-inline-item border border-light rounded-circle text-center">
              <a className="text-light text-decoration-none" target="_blank" href={x.value}><i className={`${x.icon} fa-lg fa-fw`}></i></a>
            </li>)
            }
          </ul>
        </div>
        <div className="col-lg-4 mw-auto">
          <img src={data.logo} style={{ width: '200px', display: 'initial' }} />
        </div>
        <div className="col-lg-4">
          <div className='  '>
            <div>
              Total Visitors: <span className='text-success'>{count.global}</span>
            </div>
            <div>
            This Page: <span className='text-success'>{ count.local }</span>
            </div>
          </div>
          <div>

          </div>
        </div>
        {/* <div className="col-lg-4">
          <label className="sr-only" htmlFor="subscribeEmail">Email address</label>
          <div className="input-group mb-2">
            <input type="text" className="form-control bg-dark border-light" id="subscribeEmail" placeholder="Email address" />
            <div className="input-group-text btn-success text-light">Subscribe</div>
          </div>
        </div> */}
      </div>
    </div>

    <div className="w-100 bg-black py-3">
      <div className="container">
        <div className="row pt-2">
          <div className="col-12">
            <p className="text-left text-light">
              Copyright &copy; 2024 {data.name}&nbsp;
              | &nbsp;Designed by <a rel="sponsored" href="https://www.rebaseitlabs.com/" target="_blank">RebaseITLabs</a>
            </p>
          </div>
        </div>
      </div>
    </div>

  </footer>
}